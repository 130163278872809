/*----- 13. Testimonial style  ------*/

.single-testimonial {
  img {
    border-radius: 50%;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    font-style: italic;
    line-height: 32px;

    margin: 32px 0 0;

    color: #575757;
    @media #{$xs-layout} {
      font-size: 15px;
    }
  }
  .client-info {
    margin: 20px 0 0;
    i {
      font-size: 26px;

      color: green;
    }
    h5 {
      font-size: 14px;

      margin: 22px 0 3px;

      letter-spacing: 1.5px;
      text-transform: uppercase;

      color: #010101;
    }
    span {
      font-size: 14px;

      letter-spacing: 1.5px;

      color: #010101;
    }
  }

  &--style2 {
    p {
      font-family: $josefin;
      font-size: 24px;

      color: #ffffff;
    }
    .client-info {
      h5 {
        color: #ffffff;
      }
      span {
        color: #ffffff;
      }
    }
  }
}

.testimonial-active {
  .swiper-button-next {
    right: 0 !important;
  }
  .swiper-button-prev {
    left: 0 !important;
  }

  &:hover {
    .ht-swiper-button-nav {
      visibility: visible;

      opacity: 1;
    }
  }
}

.testimonial-area {
  &:hover .nav-style-1.owl-carousel > .owl-nav div {
    visibility: visible;

    opacity: 1;
  }
  &.ml-70 {
    @media #{$xl-layout} {
      margin-left: 15px;
    }
    @media #{$lg-layout} {
      margin-left: 30px;
    }
    @media #{$md-layout} {
      margin-left: 40px;
    }
    @media #{$xs-layout} {
      margin-left: 15px;
    }
    @media #{$sm-layout} {
      margin-left: 30px;
    }
  }
  &.mr-70 {
    @media #{$xl-layout} {
      margin-right: 15px;
    }
    @media #{$lg-layout} {
      margin-right: 30px;
    }
    @media #{$md-layout} {
      margin-right: 40px;
    }
    @media #{$xs-layout} {
      margin-right: 15px;
    }
    @media #{$sm-layout} {
      margin-right: 30px;
    }
  }
  @media #{$lg-layout} {
    &.mt-195 {
      margin-top: 125px;
    }
  }
  @media #{$md-layout} {
    &.mt-195 {
      margin-top: 0;
      padding-top: 35px;
    }
  }
  @media #{$xs-layout} {
    &.mt-195 {
      margin-top: 0;
      padding-top: 40px;
    }
  }
}

.testimonial-img-2 {
  overflow: hidden;

  margin-top: -197px;
  @media #{$lg-layout} {
    margin-top: -130px;
  }
  @media #{$md-layout} {
    margin-top: 0;
  }
  @media #{$xs-layout} {
    margin-top: 30px;
  }
  img {
    width: 100%;
  }
}

.single-testimonial-2 {
  img {
    border-radius: 50%;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    font-style: italic;
    line-height: 32px;

    margin: 0;
    margin-top: 30px;

    color: #fff;
  }
  .client-info {
    margin: 18px 0 0;
    i {
      font-size: 26px;

      color: #fff;
    }
    h5 {
      font-size: 14px;
      line-height: 1;

      margin: 20px 0 6px;

      letter-spacing: 1.5px;
      text-transform: uppercase;

      color: #fff;
    }
    span {
      font-size: 14px;
      line-height: 1;

      margin: 0;

      letter-spacing: 1.5px;
      text-transform: capitalize;

      color: #fff;
    }
  }
  &.testi-paragraph-mrg {
    p {
      margin: 32px 0 0;
    }
  }
}

.testimonial-active-2 {
  .owl-dots {
    margin: 15px 0 0;

    text-align: center;
    .owl-dot {
      display: inline-block;

      margin: 0 5px;
      span {
        display: inline-block;

        width: 8px;
        height: 8px;

        border: 2px solid transparent;
        border-radius: 100%;
        background-color: #fff;
      }
      &.active {
        span {
          border: 2px solid #fff;
          background-color: transparent;
        }
      }
    }
  }
}


//  ShowBrands

.imgBxContainer{

  position: relative;
  margin: 0 auto;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  border: 2px solid rgb(144, 143, 143);

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 60px;

  @media #{$xs-layout} {
    width: 300px;
    height: 300px;
  }
}

.sideLine{
  position: absolute;
  inset: 0;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
}

.sideLine::before{
  content: " ";
  position: absolute;
  inset: 60px;
  border: 4px solid transparent;
  border-left: 2px solid #f80502;
  border-right: 2px solid #1b4b27;
  border-radius: 50%;
  animation: anim_01 5s linear infinite;
  z-index: 1;
  pointer-events: none;
}

.sideLine::after{
  content: " ";
  position: absolute;
  inset: 120px;
  border: 4px solid transparent;
  border-left: 2px solid #1b4b27;
  border-right: 2px solid #f80502;
  border-radius: 50%;
  animation: anim_02 2.5s linear infinite;
  z-index: 1;
  pointer-events: none;

  @media #{$xs-layout} {
    inset: 100px;
  }
}

.imgBxHolder{
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  // transform: translateY(-30px);

  // @media #{$xs-layout} {
  //   transform: translateY(-18px);
  // }

  .imgBx{
    position: absolute;
    left: -25px; 
    top: 165px;
    width: 60px;
    height: 60px; 
    border-radius: 50%;
    overflow: hidden;
    transition: 0.5s;
    border: 1px solid rgb(144, 143, 143);
    box-shadow: 0 0 0 2px rgb(255, 255, 255),
                0 0 0 5px rgb(192, 192, 192);
    transform-origin: 225px;
    z-index: 100;

    @media #{$xs-layout} {
      top: 130px;
      width: 40px;
      height: 40px; 
      transform-origin: 172px;
    }

    &:hover{
      box-shadow: 0 0 0 2px rgb(255, 255, 255),
                  0 0 0 5px #f80502;
      width: 80px;
      height: 80px; 

      @media #{$xs-layout} {
        width: 50px;
        height: 50px; 
      }
    }
  }
  
  .imgBx img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: scale(0.5);
    border-radius: 50%;
    object-fit: cover;
    transition: 0.5s;

  }
}

.logoHolderBrands{
  width:  60px;
  height: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  .img{
    width: 100%;

    @media #{$xs-layout} {
      width: 80%;
    }
  }
}




@keyframes anim_01 
{
  0%{
    rotate: 0deg;
  }

  100%{
    rotate: 360deg;
  }
}

@keyframes anim_02 
{
  0%{
    rotate: 360deg;
  }

  100%{
    rotate: 0deg;
  }
}
