.error {
  h1 {
    font-size: 17rem;
    font-weight: bold;
    letter-spacing: 1rem;
    line-height: 1.1;
    margin-bottom: 30px;
    color: #a749ff;
    @media #{$lg-layout} {
      font-size: 15rem;
      margin-bottom: 4px;
    }
    @media #{$md-layout} {
      font-size: 12rem;
    }
    @media #{$xs-layout} {
      font-size: 7rem;
      line-height: 88px;
    }
    @media #{$sm-layout} {
      font-size: 9rem;
      line-height: 100px;
    }
  }
  h2 {
    font-size: 25px;
    font-weight: 600;
  }
}

.error-btn-holder{
  width: 100%;
  display: flex;
  justify-content: center;
}
.error-btn {
  position: relative;
  transition: all 0.3s;
  color: #fff;
  font-size: 16px;
  min-width: 184px;
  min-height: 55px;
  line-height: 55px;
  margin-bottom: 6px;
  padding: 0 10px;
  border: none;
  border-radius: 0;
  background-color: #15591f;
  display: inline-block;
  &:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: -6px;
    height: 3px;
    width: 100%;
    background-color: #0f3314;
    transition: all 0.3s;
  }
  &:hover {
    background-color: #1b3a1f;
    color: #fff;
    &:after {
      background-color: #051207;
    }
  }
}

.searchform {
  position: relative;
  & input {
    border: 1px solid #eeeeee;
    height: 45px;
    width: 100%;
    padding-left: 15px;
    padding-right: 80px;
    background-color: transparent;
  }
  & button {
    border: none;
    background-color: #a749ff;
    width: 70px;
    color: #fff;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    &:hover {
      background-color: #000;
    }
  }
  @media #{$xs-layout} {
    &.mb-50 {
      margin-bottom: 20px;
    }
  }
}


// 

.fundo{
  animation: scales 3s alternate  infinite;
  transform-origin: center;
}
.pao-baixo{
   animation: rotatepao 14s cubic-bezier(.1,.49,.41,.97)  infinite;
  transform-origin: center;
}
.pao-cima{
   animation: rotatepao 7s 1s cubic-bezier(.1,.49,.41,.97)  infinite;
  transform-origin: center;
}
.olhos{animation: olhos   2s  alternate  infinite;
   transform-origin: center;
}

.left-sparks{animation: left-sparks   4s  alternate  infinite;
      transform-origin: 150px 156px;
}

.right-sparks{animation: left-sparks   4s  alternate  infinite;
      transform-origin: 310px 150px;
}

.olhos{animation: olhos   2s  alternate  infinite;
   transform-origin: center;
}
@keyframes scales{
  from { transform: scale(0.98)}
  to{ transform: scale(1)}
}

@keyframes rotatepao{
  0% { transform:  rotate(0deg)}
  50% , 60%{ transform:  rotate(-20deg)}
  100%{  transform:  rotate(0deg) }
 
}

@keyframes olhos{
  0%{
    transform: rotateX(0deg);
  }
   100%{
    transform: rotateX(30deg);
  }
}

@keyframes left-sparks{
  0%{
    opacity: 0; 
  }
  
}


.mainNotFound{
  
  min-height: 250px;
  margin: 0px auto;
  width: auto;
  max-width: 460px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.path {
  stroke-dasharray: 300;
  stroke-dashoffset: 300;
  animation: dash 4s   alternate infinite;
}

@keyframes dash{
   0%, 30%{
     fill: 4B4B62;
    stroke-dashoffset:   0;
  }
   80%,100%{
     fill: transparent;
    stroke-dashoffset:  -200;
  }
   
  
}